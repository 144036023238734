$(function() {
    $('#upload_cover').click(function(e) {
        $('#upload_cover_field').click();
        e.preventDefault();
    });

    $('#upload_logo').click(function(e) {
        $('#upload_logo_field').click();
        e.preventDefault();
    });

    $('#upload_logo_field').change(function() {
      $('#branding_present').val('true');
      $('.uploading-screen').show();
      $('#branding_upload_form').submit();
    });

    $('#upload_cover_field').change(function() {
      $('#branding_present').val('true');
      $('.uploading-screen').show();
      $('#branding_upload_form').submit();
    });
});
