/**
 * Campaign Sender
 */
$(function() {

    /**
     * Handle change of delivery schedule option
     */
    $(".js-campaign_sender-schedule-option input[type=radio]").change(function() {
        if($(".js-campaign_sender-schedule-option input[type=radio]:checked").val()=="at") {
            $('.js-campaign_sender-schedule-date').show();
            $('.js-campaign_sender-disclaimer-now').hide();
            $('.js-campaign_sender-disclaimer-at').show();
        } else {
            $('.js-campaign_sender-schedule-date').hide();
            $('.js-campaign_sender-schedule-date select').val('');
            $('.js-campaign_sender-disclaimer-at').hide();
            $('.js-campaign_sender-disclaimer-now').show();
        }
    });

});