var dashboardLoading = false;

$(function() {

    /**
     * Load the initial content
     */
    if($('.dashboard-content-wrap').length)
    {
        dashboardContentLoad();
    }

    /**
     * Enable tooltips
     */
    $('.js-dashboard-tooltip').tooltip({
        placement: 'top',
        trigger: 'hover',
        html: true
    });

    /**
     * Handle filter change
     */
    $('.js-dashboard-filter > ul.dropdown-menu > li > a').click(function(e) {
        if(!dashboardLoading) {
            $(this).parent().parent().children('li').removeClass('active');
            $(this).parent().addClass('active');
            $(this).closest('.js-dashboard-filter').attr('data-value', $(this).attr('data-value'));
            $(this).closest('.js-dashboard-filter').find('.js-dashboard-filter-label').text($(this).text());
            dashboardContentLoad();
        }
        e.preventDefault();
    });

    /**
     * Handle branch filter change
     */
    $('.js-dashboard-filter_branches > ul.dropdown-menu > li > a').click(function(e) {
        if(!dashboardLoading) {
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
                if (!$(this).parent().parent().children('li.active').length) {
                    $(this).parent().addClass('active');
                }
            } else {
                $(this).parent().addClass('active');
            }
            var branchesTotal = $(this).parent().parent().children('li').length;
            var branchesSelected = $(this).parent().parent().children('li.active').length;
            if (branchesSelected == branchesTotal) {
                $(this).closest('.js-dashboard-filter_branches').find('.js-dashboard-filter-label').text("All branches");
            } else if (branchesSelected == 1) {
                $(this).closest('.js-dashboard-filter_branches').find('.js-dashboard-filter-label').text("1 branch");
            } else {
                $(this).closest('.js-dashboard-filter_branches').find('.js-dashboard-filter-label').text(branchesSelected + " branches");
            }
            dashboardContentLoad();
        }
        e.preventDefault();
        return false;
    });

    /**
     * Handle toggling of rows in dashboard tables
     */
    $('.dashboard-content').on( "click", ".js-dashboard-chart_table-more-toggle-show", function(e) {
        $(this).closest('table').find('.js-dashboard-chart_table-more').css('display', 'table-row');
        $(this).hide();
        $('.js-dashboard-chart_table-more-toggle-hide').css('display', 'inline');
        return false;
    });
    $('.dashboard-content').on( "click", ".js-dashboard-chart_table-more-toggle-hide", function(e) {
        $(this).closest('table').find('.js-dashboard-chart_table-more').css('display', 'none');
        $(this).hide();
        $('.js-dashboard-chart_table-more-toggle-show').css('display', 'inline');
        return false;
    });

});

/**
 * Load the dashboard content
 */
function dashboardContentLoad() {

    // Setup
    if(dashboardLoading) return false;
    dashboardLoading = true;
    $('.dashboard-content').html('');
    $('.dashboard-content-loader').show();

    // Make an AJAX call to get the dashboard content
    $.ajax({
        type: "POST",
        url: $('.dashboard-content-wrap').attr('data-url_content'),
        data: {
            period:     $(".js-dashboard-filter[data-filter='period']").attr('data-value'),
            branch_ids: dashboardFilterBranches()
        },
        success: function(theReturn) {
            $('.dashboard-content-loader').hide();
            $('.dashboard-content').html(theReturn);
            dashboardLoading = false;
        },
        error: function() {
            $('.dashboard-content-loader > p').html('<strong>There has been an error - please refresh this page, then try again.</strong>');
        }
    });

}

/**
 * Get the selected branch IDs
 * @returns {Array}
 */
function dashboardFilterBranches() {
    var dashboardBranchIds = [];
    if($('.js-dashboard-filter_branches').length) {
        $(".js-dashboard-filter_branches > ul.dropdown-menu > li.active a").each(function(index) {
            dashboardBranchIds.push($(this).attr('data-value'));
        });
    } else {
        dashboardBranchIds.push($('.dashboard-content-wrap').attr('data-branch_id'));
    }
    return dashboardBranchIds;
}


