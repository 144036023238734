$(function() {

    /**
     * Toggle end date
     */
    $('.js-merchant-event-end-show').click(function(e) {
        $('.js-merchant-event-end-wrap').show();
        $('.js-merchant-event-end-show').hide();
        e.preventDefault();
        return false;
    });

    $('.js-merchant-event-end-hide').click(function(e) {
        $('.js-merchant-event-end-wrap').hide();
        $('.js-merchant-event-end-show').show();
        e.preventDefault();
        return false;
    });



});