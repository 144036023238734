$(function() {

    /**
     * Click on clickable table row
     */
    $('body').on("click", 'tr.js-table-row-href', function(event) {
        if(!$(event.target).is('a') && $(this).attr('data-href')!='') {
            window.location = $(this).attr('data-href');
        }
    });

    if($(".column-body").length && $(".column-body").height()+$('.header').height() < $(window).height()){
	    var newheight = $(window).height()-$('.header').height()-40;
	    $(".column-body").height(newheight);
	}

    /**
     * Attach datepickers
     */
    $('.js-datepicker').datepicker({
        format: 'dd-mm-yyyy',
        autoclose: true,
        forceParse: true,
        orientation: 'bottom',
        clearBtn: true
    });

});

$('body').on('hidden.bs.modal', '.modal', function () {
  $(this).removeData('bs.modal');
  $('.modal-header').html('<h4 class="modal-title">Loading...</h4>');
  $('.modal-body').html("");
});
