$(function() {

    /**
     * Toggle active/inactive
     */


    $('input.single-checkbox').on('change', function(evt) {
       if($("input.single-checkbox:checked").length > 1) {
          $('input.single-checkbox').removeAttr('checked');
          this.checked = true;
       }

       if($("input.js-customer-list-interacted:checked").length){
         $('.js-customer-list-inactive-wrap').slideUp();
         $('.js-customer-list-interacted-wrap').slideDown();
       } else if($("input.js-customer-list-inactive:checked").length){
         $('.js-customer-list-interacted-wrap').slideUp();
         $('.js-customer-list-inactive-wrap').slideDown();
       } else {
         $('.js-customer-list-inactive-wrap').slideUp();
         $('.js-customer-list-interacted-wrap').slideUp();
       }
    });
});
