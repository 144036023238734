// var branchLoading = false;
//
// $(function() {
//
//     /**
//      * Load the initial content
//      */
//     branchLoad();
//
// });
//
// /**
//  * Load the dashboard content
//  */
// function branchLoad() {
//
//     // Setup
//     if(branchLoading) return false;
//     branchLoading = true;
//     $('.branch-content').html();
//     $('.branch-content-loader').show();
//
//     // Make an AJAX call to get the dashboard content
//     $.ajax({
//         type: "POST",
//         url: $('.branch-content-wrap').attr('data-url_content'),
//         data: {
//             branch_ids: [$('.branch-content-wrap').attr('data-branch_id')]
//         },
//         success: function(theReturn) {
//             $('.branch-content-loader').hide();
//             $('.branch-content').html(theReturn);
//             branchLoading = false;
//         },
//         error: function() {
//             $('.branch-content-loader > p').html('<strong>There has been an error - please refresh this page, then try again.</strong>');
//         }
//     });
//
// }