$(function() {

    $('input[type=radio][name=check_in]').on('change', function(evt) {
        if (this.value == 1) {
            $('.js-merchant-checkin-wrap').slideDown();
        } else if (this.value == 0) {
            $('.js-merchant-checkin-wrap').slideUp();
        }
    });

    $('input[type=radio][name=create_login]').on('change', function(evt) {
        if (this.value == 1) {
            $('.js-merchant-login-wrap').slideDown();
        } else if (this.value == 0) {
            $('.js-merchant-login-wrap').slideUp();
        }
    });

    $('input[type=radio][name=brand_colour]').on('change', function(evt) {
        $('#brand-preview').removeClass();
        $('#brand-preview').addClass('merchant-brand-preview');
        $('#brand-preview').addClass('colour-'+this.value);
    });

    $('#business-name-input').on('change', function(evt) {
        $('#brand-preview .business-name').html(this.value);
    });

});
